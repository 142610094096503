<template>
  <div>

    <b-card-actions
      title="Filters"
      action-collapse
    >
      <!-- Filter -->
      <b-row>

        <b-col
          md="2"
          lg="2"
          xs="12"
        >
          <v-select
            v-model="selectEmpId"
            :options="filterEmpIdOption"
            :reduce="(item) => item.id"
            label="name"
            placeholder="Select Employee"
            class="mb-1 custom-font"
            @input="loadItems('employee')"
          >
            <template #option="data">
              <UserSelect :user="data" />
            </template>
          </v-select>
        </b-col>

        <b-col
          md="2"
          lg="2"
          xs="12"
        >
          <v-select
            v-model="filterRoleId"
            :options="roleIdOption"
            :reduce="(item) => item.id"
            label="name"
            placeholder="Select Role"
            class="mb-1 custom-font"
            @input="loadEmp('role')"
          />
        </b-col>
        <b-col
          md="2"
          lg="2"
          xs="12"
        >
          <v-select
            v-model="filterDivisionId"
            :options="filterDivisionIdOption"
            :reduce="(item) => item.id"
            label="name"
            placeholder="Select Division"
            class="mb-1 custom-font"
            @input="loadEmp('division')"
          />
        </b-col>
        <b-col
          md="2"
          lg="2"
          xs="12"
        >
          <v-select
            v-model="filterDepartmentId"
            :options="filterDepartmentIdOption"
            :reduce="(item) => item.id"
            label="name"
            placeholder="Select Department"
            class="mb-1 custom-font"
            @input="loadEmp('department')"
          />
        </b-col>

        <b-col
          md="2"
          lg="2"
          xs="12"
        >
          <v-select
            v-model="filterDesignationId"
            :options="filterDesignationIdOption"
            :reduce="(item) => item.id"
            label="name"
            placeholder="Select Designation"
            class="mb-1 custom-font"
            @input="loadEmp('designation')"
          />
        </b-col>
        <b-col
          md="2"
          lg="2"
          xs="12"
        >
          <v-select
            v-model="filterStatusId"
            :options="statusIdOption"
            :reduce="(item) => item.value"
            label="name"
            placeholder="Select Status"
            class="mb-1 custom-font"
            @input="filterStatusWiseInfo"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card>
      <div>

        <!-- create employee -->
        <div class="custom-search d-flex align-items-center justify-content-end">
          <div
            class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
          >
            <template v-if="$permissionAbility(USERS_CREATE, permissions)">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="flex-shrink-0"
                variant="primary"
                @click="showModal"
              >
                Add Employee
              </b-button>
            </template>
          </div>
        </div>

        <!-- table -->
        <vue-good-table

          mode="remote"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          :rows="rows"
          :sort-options="{
            enabled: false,
            multipleColumns: true,
            initialSortBy: [
              { field: 'id', type: 'desc' },
              { field: 'name', type: 'desc' },
              { field: 'created_at', type: 'desc' },
            ],
          }"
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          style-class="vgt-table striped"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: head -->
            <span v-if="props.column.field === 'format_name'">

              <UserCard
                :user="props.row"
                @click="onShowPage(props.row.id)"
              />

            </span>

            <!-- Column: Status -->
            <span v-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status ? "Active" : "Inactive" }}
              </b-badge>
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">

              <template
                v-if="$permissionAbility(USERS_PROFILE_ACCESS, permissions)"
              >
                <span @click="onShowPage(props.row.id)">
                  <feather-icon
                    v-b-tooltip.hover
                    icon="EyeIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Profile"
                    size="16"
                  />
                </span>
              </template>

              <template v-if="$permissionAbility(USERS_EDIT, permissions)">
                <span @click="onShow(props.row)">
                  <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    size="16"
                  />
                </span>
              </template>

              <template v-if="(props.row.super_admin !== 1 && props.row.admin !== 1) && $permissionAbility(USERS_DELETE, permissions)">
                <span @click="onPermanentlyDelete(props.row)">
                  <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Delete"
                    size="16"
                  />
                </span>
              </template>

            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '25', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      <b-modal
        id="modal-users-form"
        centered
        :title="modelType === 'editModel'
          ? 'Edit Employee'
          : 'Register Employee'
        "
        hide-footer
        no-close-on-backdrop
        size="lg"
        @hidden="hiddenModal"
      >
        <validation-observer ref="usersValidation">
          <b-form @submit.prevent="validationForm">
            <b-row>
              <!-- name -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Profile Image "
                  label-for="avatar"
                >
                  <validation-provider
                    name="profile image"
                    vid="avatar"
                    rules="size:2048|ext:jpg,jpeg,png"
                  >
                    <b-row>
                      <b-col
                        xs="6"
                        lg="6"
                        md="6"
                      >
                        <b-avatar
                          ref="previewEl"
                          :src="previewAvatar"
                          size="90px"
                          rounded
                        />
                      </b-col>
                      <b-col
                        class="mt-1"
                        xs="6"
                        lg="6"
                        md="6"
                      >
                        <b-button
                          variant="outline-primary"
                          class="btn-sm mr-1"
                          @click="$refs.refInputEl.click()"
                        >
                          <input
                            ref="refInputEl"
                            type="file"
                            class="d-none"
                            accept="image/*"
                            @input="uploadImage"
                          >
                          <feather-icon icon="UploadIcon" />
                        </b-button>
                        <b-button
                          variant="outline-danger"
                          class="btn-sm mr-1 mt-1"
                          @click="removeImage"
                        >
                          <feather-icon icon="MinusSquareIcon" />
                        </b-button>
                      </b-col>
                    </b-row>
                    <template v-if="imageSizeError">
                      <small class="text-danger">{{ imageSizeError }}</small>
                    </template>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    :rules="`${
                      modelType == 'editModel' ? '' : 'required'
                    }|max:255`"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      name="name"
                      placeholder="Enter Full Name"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- employee_number -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Employee ID "
                  label-for="employee-number"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="employee id"
                    vid="employee_number"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="employee-number"
                      v-model="employeeNumber"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="EM-220001"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Machine Emp ID"
                  label-for="machine-employee-number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="machine employee id"
                    vid="machine_user_id"
                    rules="numeric"
                  >
                    <b-form-input
                      id="machine-employee-number"
                      v-model="machineEmployeeId"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="220001"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- nid -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="National ID / Social Security Number "
                  label-for="nid"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="national id / social security number"
                    :rules="`${modelType === 'editModel' ? '' : 'required'}`"
                    vid="nid"
                  >
                    <b-form-input
                      id="nid"
                      v-model="nid"
                      :state="errors.length > 0 ? false : null"
                      name="nid"
                      placeholder="NID / SSN Number"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- email -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Email "
                  label-for="email"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    type="email"
                    name="email"
                    :rules="`${modelType === 'editModel' ? '' : 'required'}|email`"
                    vid="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      :state="errors.length > 0 ? false : null"
                      name="email"
                      placeholder="john@example.com"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Personal Email "
                  label-for="email"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    type="email"
                    name="personal email"
                    :rules="`required|email`"
                    vid="personal_email"
                  >
                    <b-form-input
                      id="personal_email"
                      v-model="personal_email"
                      :state="errors.length > 0 ? false : null"
                      name="personal email"
                      placeholder="john@example.com"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- mobile -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Mobile "
                  label-for="mobile"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="mobile"
                    :rules="`${modelType === 'editModel' ? '' : 'required'}`"
                    vid="mobile"
                  >
                    <b-form-input
                      id="mobile"
                      v-model="mobile"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      name="mobile"
                      placeholder="Mobile Number"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- DOB -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Date Of Birth "
                  label-for="dob"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="date of birth"
                    vid="dob"
                    rules="required"
                  >
                    <flat-pickr
                      id="dob"
                      v-model="dob"
                      class="form-control"
                      placeholder="Select Date"
                      :state="errors.length > 0 ? false : null"
                      :config="datePickerConfig"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- Blood Group -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Blood Group "
                  label-for="blood-group"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="blood group"
                    vid="blood_group"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="selectBloodGroupValue"
                      :options="bloodGroupTypeConstants"
                      :reduce="(option) => option.value"
                      label="name"
                      placeholder="Choose Here"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- religion -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Religion "
                  label-for="religion"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="religion"
                    vid="religion"
                    rules="required"
                  >
                    <v-select
                      id="religion"
                      v-model="selectReligionValue"
                      :options="religionValueOption"
                      :reduce="(option) => option.value"
                      label="name"
                      placeholder="Choose Here"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- Join Date -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Gender "
                  label-for="gender"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="gender"
                    vid="gender"
                    rules="required"
                  >
                    <v-select
                      id="gender"
                      v-model="selectGenderId"
                      placeholder="Choose a gender"
                      :options="genderOption"
                      :reduce="(option) => option.value"
                      class="custom-font"
                      @input="updateLeaveTypeOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- select gender-->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Marital Status "
                  label-for="martial status"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="marital status"
                    vid="marital_status"
                    rules="required"
                  >
                    <v-select
                      id="marital_status"
                      v-model="selectMaritalStatusValue"
                      :options="maritalStatusValueOption"
                      :reduce="(option) => option.value"
                      label="name"
                      placeholder="Choose Here"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- martial status -->

              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Work Type"
                  label-for="work_type"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Work Type"
                    vid="work_type"
                    rules="required"
                  >
                    <v-select
                      id="work_type"
                      v-model="selectWorkTypeValue"
                      :options="workTypeValueOption"
                      :reduce="(option) => option.value"
                      label="name"
                      placeholder="Choose Here"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Join Date "
                  label-for="join-date"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="join date"
                    vid="join_date"
                    rules="required"
                  >
                    <flat-pickr
                        id="join-date"
                        v-model="joinDate"
                        class="form-control"
                        placeholder="Select Date"
                        :state="errors.length > 0 ? false : null"
                        :config="datePickerConfig"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>

              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Designation "
                  label-for="designation-id"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="designation"
                    vid="designation_id"
                    rules="required"
                  >
                    <v-select
                      id="designation-id"
                      v-model="selectDesignationId"
                      placeholder="Choose a designation"
                      :options="designationIdOption"
                      :reduce="(option) => option.id"
                      label="name"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- select role -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Role "
                  label-for="role-id"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="role"
                    vid="role_id"
                    rules="required"
                  >
                    <v-select
                      id="role-id"
                      v-model="selectRoleId"
                      placeholder="Choose role"
                      :options="roleIdOption"
                      :reduce="(option) => option.id"
                      label="name"
                      class="custom-font"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col
                xs="12"
                lg="4"
                md="6"
              >
                <b-form-group
                  label="Agreement Renewal Date"
                  label-for="agreement-renewal-date"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="agreement renewal date"
                    vid="agreement_renewal_date"
                    rules="required"
                  >
                    <flat-pickr
                        id="agreement renewal date"
                        v-model="agreementRenewalDate"
                        class="form-control"
                        placeholder="Select Date"
                        :state="errors.length > 0 ? false : null"
                        :config="datePickerConfig"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col
                xs="12"
                lg="4"
                md="6"
              >
                <b-form-group
                  label="Festival Bonus Eligibility Months"
                  label-for="festival_bonus_eligibility_months"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Festival Bonus Eligibility Months"
                    vid="festival_bonus_eligibility_months"
                    rules="integer|min:1|max:24"
                  >
                    <b-form-input
                      id="festival_bonus_eligibility_months"
                      v-model="festivalBonusEligibilityMonths"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      name="festival_bonus_eligibility_months"
                      placeholder="Enter Months"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- select department -->
              <b-col
                xs="12"
                lg="4"
                md="6"
              >
                <b-form-group
                  label="Department "
                  label-for="department-id"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="department"
                    vid="department_id"
                    rules="required"
                  >
                    <v-select
                      id="department-id"
                      v-model="selectDepartmentId"
                      placeholder="Choose a department"
                      :options="departmentIdOption"
                      :reduce="(option) => option.id"
                      label="name"
                      class="custom-font"
                      @input="departmentChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="modelType === ''"
                xs="12"
                lg="4"
                md="6"
              >
                <b-form-group
                  label="Attendance Policy "
                  label-for="attendance_policy"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="attendance policy"
                    vid="attendance_policy"
                    rules="required"
                  >
                    <v-select
                      id="attendance_policy"
                      v-model="selectAttendancePolicyId"
                      placeholder="Choose a attendance policy"
                      :options="attendancePolicyOption"
                      :reduce="(option) => option.id"
                      label="name"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Employee Type "
                  label-for="employee-type-id"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="employee type"
                    vid="employee_type_id"
                    rules="required"
                  >
                    <v-select
                      id="employee-type-id"
                      v-model="selectEmployeeTypesId"
                      placeholder="Choose a employee type"
                      :options="employeeTypesOption"
                      :reduce="(option) => option.id"
                      label="name"
                      class="custom-font"
                      @input="updateLeaveTypeOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- v-if="modelType == ''" -->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Select Leave Type"
                  label-for="leave-type-id"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="leave type"
                    vid="leave_type_id"
                    autocomplete="off"
                  >
                    <v-select
                      id="leave-type-id"
                      v-model="selectLeaveTypesIds"
                      placeholder="Choose leave "
                      :options="leaveTypesOption"
                      :reduce="(option) => option.id"
                      label="name"
                      class="custom-font"
                      autocomplete="off"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- select status-->
              <b-col
                xs="12"
                lg="4"
                md="4"
              >
                <b-form-group
                  label="Status "
                  label-for="status"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="status"
                    vid="status"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="selectStatusValue"
                      :options="statusValueOption"
                      :reduce="(option) => option.value"
                      label="name"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <template v-if="modelType == ''">
                <b-col
                  xs="12"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label="Monthly Salary "
                    label-for="salary"
                    class="required-label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="salary"
                      :rules="`${modelType === 'editModel' ? '' : 'required'}`"
                      vid="salary"
                    >
                      <b-form-input
                        id="salary"
                        v-model="salary"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        name="salary"
                        placeholder="Monthly Salary Amount"
                        class="custom-font"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- password -->
                <b-col
                  xs="12"
                  lg="4"
                  md="6"
                >
                  <b-form-group
                    label="Password "
                    label-for="password"
                    class="required-label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="password"
                      :rules="`${
                        modelType == 'editModel' ? '' : 'required'
                      }|min:6`"
                      vid="password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password"
                          v-model="password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge custom-font"
                          :type="passwordFieldType"
                          name="user-password"
                          placeholder="············"
                        />
                        <b-input-group-append
                          id="passwordToggleBtn"
                          is-text
                          @click="togglePasswordVisibility"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Confirm Password -->
                <b-col
                  xs="12"
                  lg="4"
                  md="6"
                >
                  <b-form-group
                    label="Confirm Password "
                    label-for="password_confirmation"
                    class="required-label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="password confirmation"
                      :rules="`${
                        modelType == 'editModel' ? '' : 'required'
                      }|confirmed:password`"
                      vid="password_confirmation"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password_confirmation"
                          v-model="password_confirmation"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="password_confirmation"
                          placeholder="············"
                          :type="confirmPasswordFieldType"
                        />
                        <b-input-group-append
                          id="passwordToggleBtn"
                          is-text
                          @click="toggleConfirmPasswordVisibility"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            :icon="confirmPasswordToggleIcon"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>

              <!-- submit and reset -->
              <b-col cols="12">
                <!-- loading button -->
                <template v-if="isUserFormSubmitLoading">
                  <b-button
                    class="float-right"
                    variant="primary"
                    disabled
                  >
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    class="float-right"
                    variant="primary"
                  >
                    {{ modelType === "editModel" ? "Update" : "Submit" }}
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>

  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  email,
  max,
  mimes,
  ext,
  size,
  confirmed,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'

import {
  USERS_CREATE,
  USERS_DELETE,
  USERS_EDIT,
  USERS_PERMANENTLY_DELETE,
  USERS_PROFILE_ACCESS,
} from '@/helpers/permissionsConstant'
import { bloodGroupTypeConstants } from '@/helpers/constant/bloodGroupTypeConstant'
import { religionTypeConstants as religionValueOption } from '@/helpers/constant/religionTypeConstant'
import { maritalStatusTypeConstants as maritalStatusValueOption } from '@/helpers/constant/maritalStatusTypeConstant'
import { workTypeConstants as workTypeValueOption } from '@/helpers/constant/workTypeConstant'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserCard from '@/layouts/components/UserCard.vue'
import UserSelect from '@/layouts/components/UserSelect.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'UsersView',
  components: {
    UserSelect,
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    UserCard,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BFormDatepicker,
    BCardActions,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      USERS_CREATE,
      USERS_EDIT,
      USERS_DELETE,
      USERS_PERMANENTLY_DELETE,
      USERS_PROFILE_ACCESS,

      datePickerConfig: {static: true},

      testLoad: false,
      isSuperAdmin: false,

      // filter division
      selectEmpId: '',
      filterDivisionId: '',
      filterDivisionIdOption: [],
      filterEmpIdOption: [],
      // filter department
      filterDepartmentId: '',
      filterDepartmentIdOption: [],
      // filter designation
      filterDesignationId: '',
      filterDesignationIdOption: [],
      // filter role
      filterRoleId: '',
      filterStatusId: '',
      statusIdOption: [
        {
          name: 'Active',
          value: true,
        },
        {
          name: 'Inactive',
          value: false,
        },
      ],

      modelType: '',
      imageSizeError: '',
      userId: '',
      avatar: null,
      name: '',
      nid: '',
      employeeNumber: '',
      machineEmployeeId: '',
      password: '',
      password_confirmation: '',
      email: '',
      personal_email: '',
      mobile: '',
      dob: '',
      salary: '',
      selectAttendancePolicyId: '',
      selectDepartmentId: '',
      selectBloodGroupValue: '',
      agreementRenewalDate: '',
      festivalBonusEligibilityMonths: '',
      bloodGroupTypeConstants,
      selectReligionValue: '',
      religionValueOption,
      selectMaritalStatusValue: '',
      selectWorkTypeValue: '',
      maritalStatusValueOption,
      workTypeValueOption,
      joinDate: '',
      selectRoleId: [],
      roleIdOption: [],
      selectGenderId: '',
      previewAvatar: null,
      genderOption: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Others', value: 'Others' },
      ],
      selectStatusValue: true,
      statusValueOption: [
        {
          name: 'Active',
          value: true,
        },
        {
          name: 'Inactive',
          value: false,
        },
      ],
      selectDesignationId: '',
      designationIdOption: [],
      selectEmployeeTypesId: '',
      employeeTypesOption: [],
      selectLeaveTypesIds: [],
      leaveTypesOption: [],
      departmentIdOption: [],
      attendancePolicyOption: [],
      pageLength: 10,
      columns: [
        {
          label: 'Name',
          field: 'format_name',
        },
        {
          label: 'Role',
          field: this.roleName,
          sortable: false,
        },
        {
          label: 'Designation',
          field: 'designation.data.name',
          sortable: false,
        },
        {
          label: 'Type',
          field: 'employeeType.data.name',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Joining Date',
          field: 'join_date',
          formatFn: this.formatDate,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      delayTimer: null,
      isLoading: false,
      isUserFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: 'id', type: 'desc' },
          { field: 'name', type: 'desc' },
          { field: 'created_at', type: 'desc' },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },

  computed: {
    ...mapGetters({
      user: 'userModule/getUser',
      permissions: 'userModule/getPermissions',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    statusVariant() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },
  },

  async created() {
    try {
      const [
        roles,
        designations,
        getEmployeeTypes,
        getLeaveTypesAll,
        departments,
        attendancePolicy,
        division,
        department,
        designation,
        employees,
      ] = await Promise.all([
        this.getRoles(),
        this.getDesignations(),
        this.getEmployeeTypes(),
        this.getLeaveTypesAll(),
        this.getDepartments(),
        this.getAttendancePolicy(),
        this.getDivision(),
        this.getDepartment(),
        this.getDesignation(),
        this.getEmployees(),
      ])

      // roles
      this.roleIdOption = (roles?.data?.data || []).map(item => ({
        name: item?.name,
        id: item?.id,
      }))

      // departments
      this.departmentIdOption = (departments?.data?.data || []).map(item => ({
        name: item?.name,
        id: item?.id,
      }))

      // designations
      this.designationIdOption = (designations?.data?.data || []).map(
        item => ({
          name: item?.name,
          id: item?.id,
        }),
      )
      // designations
      this.employeeTypesOption = (getEmployeeTypes?.data?.data || []).map(
        item => ({
          name: item?.name,
          id: item?.id,
          leave_types: item?.leave_types,
        }),
      )

      // employee
      this.filterEmpIdOption = (employees?.data?.data || []).map(item => ({
        avatar: item?.avatar,
        name: item?.name,
        email: item?.email,
        mobile: item?.mobile,
        employee_number: item?.employee_number,
        id: item?.id,
        departmentId: item?.department_id,
      }))

      //  getLeaveTypesAll
      this.leaveTypesOption = (getLeaveTypesAll?.data?.data || []).map(
        item => ({
          name: item?.name,
          gender: item?.gender,
          id: item?.id,
        }),
      )

      // filter division
      this.filterDivisionIdOption = (division?.data?.data || []).map(item => {
        const { name } = item
        return {
          name,
          id: item.id,
        }
      })
      // if (!this.filterDivisionId) {
      // filter department
      this.filterDepartmentIdOption = (department?.data?.data || []).map(
        item => {
          const { name } = item
          return {
            name,
            id: item.id,
          }
        },
      )
      // }
      // filter designation
      this.filterDesignationIdOption = (designation?.data?.data || []).map(
        item => {
          const { name } = item
          return {
            name,
            id: item.id,
          }
        },
      )
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },

  methods: {
    async updateLeaveTypeOptions() {
      const getLeaveTypesAll = await this.getLeaveTypesAll()

      this.leaveTypesOption = (getLeaveTypesAll?.data?.data || []).map(
        item => ({
          gender: item?.gender,
          name: item?.name,
          id: item?.id,
        }),
      )

      const selectedGender = this.selectGenderId
      const selectedEmployeeTypeId = this.selectEmployeeTypesId
      const leaveTypes = this.leaveTypesOption
      const employeeTypes = this.employeeTypesOption

      let selectedLeaveTypeIds = [] // Initialize an array to store selected leave type IDs

      if (selectedEmployeeTypeId) {
        // Find the selected employee type
        const selectedEmployeeType = employeeTypes.find(
          employeeType => employeeType.id === selectedEmployeeTypeId,
        )
        // Get all leave type IDs for the selected employee type
        // Select leave type IDs based on the employee type
        selectedLeaveTypeIds = selectedEmployeeType.leave_types.map(lt => lt.id)
      }

      if (selectedGender) {
        // Further select leave type IDs based on gender
        selectedLeaveTypeIds = selectedLeaveTypeIds.filter(id => {
          const leaveType = leaveTypes.find(lt => lt.id === id)
          console.log(leaveType)
          return leaveType.gender === selectedGender || leaveType.gender === 'All' || leaveType.gender === ''
        })
      }

      // Update selectLeaveTypesIds with the selected leave type IDs
      this.selectLeaveTypesIds = selectedLeaveTypeIds
    },

    async setDivisionWiseInfo(divisionId) {
      this.filterDivisionId = divisionId
      this.loadItems()
    },
    async setDepartmentWiseInfo(departmentId) {
      this.filterDepartmentId = departmentId
      this.loadItems()
    },
    async filterDesignationWiseInfo() {
      this.loadItems()
    },
    async filterStatusWiseInfo(check) {
      console.log(check)
      this.loadItems()
    },

    async loadEmp(value) {
      if (value == 'division') {
        this.filterDepartmentId = ''
        this.selectEmpId = ''

        if (this.filterDivisionId) {
          const departments = await this.getDepartments()
          // departments
          this.filterDepartmentIdOption = (departments?.data?.data || []).map(item => ({
            name: item?.name,
            id: item?.id,
            divisionId: item?.division_id,
          }))

          this.filterDepartmentIdOption = this.filterDepartmentIdOption.filter(
            item => item?.divisionId == this.filterDivisionId,
          )
        } else {
          const [
            departments,
            employees,
          ] = await Promise.all([
            this.getDepartments(),
            this.getEmployees(),
          ])

          // departments
          this.filterDepartmentIdOption = (departments?.data?.data || []).map(item => ({
            name: item?.name,
            id: item?.id,
          }))

          this.filterEmpIdOption = (employees?.data?.data || []).map(item => ({
            avatar: item?.avatar,
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            employee_number: item?.employee_number,
            id: item?.id,
            departmentId: item?.department_id,
          }))
        }
      }

      if (value == 'department') {
        this.selectEmpId = ''
        if (this.filterDepartmentId) {
          const employees = await this.getEmployees()

          this.filterEmpIdOption = (employees?.data?.data || []).map(item => ({
            avatar: item?.avatar,
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            employee_number: item?.employee_number,
            id: item?.id,
            departmentId: item?.department_id,
          }))

          this.filterEmpIdOption = this.filterEmpIdOption.filter(
            item => item?.departmentId == this.filterDepartmentId,
          )
        } else {
          const [
            employees,
          ] = await Promise.all([
            this.getEmployees(),
          ])

          this.filterEmpIdOption = (employees?.data?.data || []).map(item => ({
            avatar: item?.avatar,
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            employee_number: item?.employee_number,
            id: item?.id,
            departmentId: item?.department_id,
          }))
        }
      }

      this.loadItems()
    },

    async departmentChanged() {
      this.attendancePolicyOption = []
      this.selectAttendancePolicyId = ''

      if (this.selectDepartmentId) {
        const attendancePolicy = await this.getAttendancePolicy()

        const attendancePolicyOptionData = (attendancePolicy?.data.data || []).filter(item => item?.departments?.some(department => department.id === this.selectDepartmentId))

        // attendance policy
        this.attendancePolicyOption = (attendancePolicyOptionData || []).map(item => ({
          name: item?.division.data.name,
          id: item?.id,
        }))
      }
    },
    async getDepartments() {
      return await this.$api.get('api/departments/all')
    },
    async getAttendancePolicy() {
      return await this.$api.get('api/attendances/policy/remote/map?include=division,remoteAttendancePolicy')
    },
    onShowPage(id) {
      this.$router.push({
        name: 'user-profile',
        params: { id },
      })
    },
    removeImage() {
      this.previewAvatar = null
      this.avatar = null
      this.imageSizeError = ''
    },
    uploadImage() {
      const input = this.$refs.refInputEl
      const file = input.files

      if (parseInt(file[0].size) / 1000 <= 2048) {
        this.imageSizeError = ''
        if (file && file[0]) {
          const reader = new FileReader()
          reader.onload = e => {
            this.previewAvatar = e.target.result
          }
          reader.readAsDataURL(file[0])
          this.avatar = file[0]
        }
      } else {
        this.previewAvatar = null
        this.avatar = null
        this.imageSizeError = 'Profile Image Maximum 2048 kb Allowed'
      }
    },
    onContextPurchaseDate(ctx) {
      this.purchaseDate = ctx.selectedYMD
    },

    roleName(rowObj) {
      return rowObj?.roles?.data[0]?.name
    },
    showModal() {
      this.$bvModal.show('modal-users-form')
    },
    hiddenModal() {
      this.$bvModal.hide('modal-users-form')
      this.resetModal()
    },
    resetModal() {
      this.modelType = ''
      this.userId = ''
      this.name = ''
      this.nid = ''
      this.selectRoleId = []
      this.email = ''
      this.personal_email = ''
      this.mobile = ''
      this.dob = ''
      this.selectStatusValue = true
      this.selectBloodGroupValue = ''
      this.selectReligionValue = ''
      this.selectMaritalStatusValue = ''
      this.selectWorkTypeValue = ''
      this.joinDate = '';
      (this.agreementRenewalDate = ''), (this.password = '')
      this.password_confirmation = ''
      this.selectDesignationId = ''
      this.selectEmployeeTypesId = ''
      this.selectLeaveTypesIds = []
      this.employeeNumber = ''
      this.previewAvatar = ''
      this.selectDepartmentId = ''
      this.selectAttendancePolicyId = ''
      this.avatar = null
      this.machineEmployeeId = ''
      this.festivalBonusEligibilityMonths = ''
      this.agreementRenewalDate = ''
    },
    async onShow(value) {
      this.modelType = 'editModel'
      this.userId = value?.id
      this.name = value?.name
      this.email = value?.email
      this.personal_email = value?.personal_email
      this.mobile = value?.mobile
      this.nid = value?.nid
      this.joinDate = value?.join_date
      this.agreementRenewalDate = value?.agreement_renewal_date
      this.dob = value?.dob
      this.selectMaritalStatusValue = value?.marital_status
      this.selectWorkTypeValue = value?.work_type
      this.selectReligionValue = value?.religion
      this.selectBloodGroupValue = value?.blood_group
      this.selectRoleId = (value?.roles?.data || []).map(
        item => item?.id,
      )

      this.isSuperAdmin = value?.super_admin
      this.selectStatusValue = value?.status
      this.festivalBonusEligibilityMonths = value?.festival_bonus_eligibility_month
      this.selectDesignationId = value?.designation_id
        ? +value?.designation_id
        : ''
      this.selectGenderId = value?.gender
      this.selectDepartmentId = value?.department_id
        ? +value?.department_id
        : ''

      this.selectAttendancePolicyId = value?.department_id
        ? +value?.department_id
        : ''

      this.employeeNumber = value?.employee_number
      this.previewAvatar = value?.avatar

      this.selectEmployeeTypesId = value?.employee_type_id
        ? +value?.employee_type_id
        : ''

      this.selectLeaveTypesIds = (value?.leave_types || []).map(
        item => item?.id,
      )

      this.machineEmployeeId = value?.machine_user_id
      this.agreementRenewalDate = value?.agreement_renewal_date

      this.showModal()
    },
    async onDelete(row) {
      const { id, name } = row
      this.$swal({
        title: 'Warning!',
        text: `Are You Sure You Want To Delete ${name}?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/users/${id}`)

            this.loadItems()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Successfully Deleted',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          }
        }
      })
    },
    async onPermanentlyDelete(row) {
      const { id, name } = row
      this.$swal({
        title: 'Warning!',
        text: `Are You Sure You Want To Delete ${name}?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/users/${id}/force-delete`)

            this.loadItems()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Successfully Deleted',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          }
        }
      })
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems()
      }, 1000)
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    async getUsers(params) {
      return await this.$api.get('api/users?include=roles,designation,employeeType', {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          filterDivision: params.filterDivision,
          filterDepartment: params.filterDepartment,
          filterDesignation: params.filterDesignation,
          filterStatus: params.filterStatus,
          filterEmp: params.selectEmpId,
          filterRole: params.filterRole,
        },
      })
    },
    async getRoles() {
      return await this.$api.get('api/roles/all')
    },
    async getDesignations() {
      return await this.$api.get('api/designations/all')
    },
    async getEmployeeTypes() {
      return await this.$api.get('api/employee-types')
    },
    async getLeaveTypesAll() {
      return await this.$api.get('api/leave-types/current-year-all')
    },
    async getDivision() {
      return await this.$api.get('api/divisions/all')
    },
    async getDepartment() {
      return await this.$api.get('api/departments/all')
    },
    async getDesignation() {
      return await this.$api.get('api/designations/all')
    },
    async getEmployees() {
      return await this.$api.get('api/users/all')
    },
    async loadItems() {
      try {
        const [users] = await Promise.all([
          this.getUsers({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterDivision: this.filterDivisionId,
            filterDepartment: this.filterDepartmentId,
            filterDesignation: this.filterDesignationId,
            filterStatus: this.filterStatusId,
            selectEmpId: this.selectEmpId,
            filterRole: this.filterRoleId,
          }),
        ])

        const data = users?.data?.data

        const meta = users?.data?.meta

        this.totalRecords = meta?.pagination?.total

        this.rows = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async validationForm() {
      this.$refs.usersValidation.validate().then(async success => {
        if (success) {
          if (this.imageSizeError == '') {
            try {
              if (this.modelType == 'editModel') {
                this.isUserFormSubmitLoading = true

                const formData = new FormData()

                formData.append('_method', 'PUT')

                if (this.avatar) {
                  formData.append('avatar', this.avatar)
                }

                if (this.name) {
                  formData.append('name', this.name)
                }

                if (this.nid) {
                  formData.append('nid', this.nid)
                }

                if (this.email) {
                  formData.append('email', this.email)
                }

                if (this.personal_email) {
                  formData.append('personal_email', this.personal_email)
                }

                if (this.mobile) {
                  formData.append('mobile', this.mobile)
                }

                if (this.dob) {
                  formData.append('dob', this.dob)
                }

                if (this.joinDate) {
                  formData.append('join_date', this.joinDate)
                }

                if (this.agreementRenewalDate) {
                  formData.append(
                    'agreement_renewal_date',
                    this.agreementRenewalDate,
                  )
                }
                if (this.selectBloodGroupValue) {
                  formData.append('blood_group', this.selectBloodGroupValue)
                }

                if (this.selectReligionValue) {
                  formData.append('religion', this.selectReligionValue)
                }

                if (this.selectMaritalStatusValue) {
                  formData.append(
                    'marital_status',
                    this.selectMaritalStatusValue,
                  )
                }

                if (this.selectWorkTypeValue) {
                  formData.append(
                    'work_type',
                    this.selectWorkTypeValue,
                  )
                }

                formData.append('status', this.selectStatusValue)

                if (this.password) {
                  formData.append('password', this.password)
                }

                if (this.password_confirmation) {
                  formData.append(
                    'password_confirmation',
                    this.password_confirmation,
                  )
                }

                if (this.selectRoleId && Array.isArray(this.selectRoleId)) {
                  this.selectRoleId.forEach(roleId => {
                    formData.append('role_id[]', roleId) // Append each role ID individually
                  })
                }

                if (this.selectDesignationId) {
                  formData.append('designation_id', this.selectDesignationId)
                }

                if (this.selectGenderId) {
                  formData.append('gender', this.selectGenderId)
                }

                if (this.selectEmployeeTypesId) {
                  formData.append(
                    'employee_type_id',
                    this.selectEmployeeTypesId,
                  )
                }

                if (this.festivalBonusEligibilityMonths) {
                  formData.append(
                    'festival_bonus_eligibility_month',
                    this.festivalBonusEligibilityMonths,
                  )
                }

                if (this.selectLeaveTypesIds.length > 0) {
                  this.selectLeaveTypesIds.forEach(element => {
                    formData.append('leave_type_id[]', element)
                  })
                }
                if (this.selectDepartmentId) {
                  formData.append('department_id', this.selectDepartmentId)
                }

                if (this.employeeNumber) {
                  formData.append('employee_number', this.employeeNumber)
                }

                if (this.machineEmployeeId) {
                  formData.append('machine_user_id', this.machineEmployeeId)
                }

                if (this.agreementRenewalDate) {
                  formData.append(
                    'agreement_renewal_date',
                    this.agreementRenewalDate,
                  )
                }

                if (this.status) {
                  formData.append(
                    'status',
                    this.status,
                  )
                }

                await this.$api.post(`/api/users/${this.userId}`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })

                this.isUserFormSubmitLoading = false
                this.loadItems()
                this.hiddenModal()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully Updated',
                  },
                })
              } else {
                this.isUserFormSubmitLoading = true
                const formData = new FormData()

                if (this.avatar) {
                  formData.append('avatar', this.avatar)
                }

                if (this.name) {
                  formData.append('name', this.name)
                }
                if (this.nid) {
                  formData.append('nid', this.nid)
                }

                if (this.email) {
                  formData.append('email', this.email)
                }

                if (this.personal_email) {
                  formData.append('personal_email', this.personal_email)
                }

                if (this.salary) {
                  formData.append('salary', this.salary)
                }

                if (this.mobile) {
                  formData.append('mobile', this.mobile)
                }

                if (this.dob) {
                  formData.append('dob', this.dob)
                }

                if (this.joinDate) {
                  formData.append('join_date', this.joinDate)
                }
                if (this.agreementRenewalDate) {
                  formData.append(
                    'agreement_renewal_date',
                    this.agreementRenewalDate,
                  )
                }

                if (this.selectBloodGroupValue) {
                  formData.append('blood_group', this.selectBloodGroupValue)
                }

                if (this.selectReligionValue) {
                  formData.append('religion', this.selectReligionValue)
                }

                if (this.selectMaritalStatusValue) {
                  formData.append(
                    'marital_status',
                    this.selectMaritalStatusValue,
                  )
                }

                if (this.selectWorkTypeValue) {
                  formData.append(
                    'work_type',
                    this.selectWorkTypeValue,
                  )
                }

                formData.append('status', this.selectStatusValue)

                if (this.password) {
                  formData.append('password', this.password)
                }

                if (this.password_confirmation) {
                  formData.append(
                    'password_confirmation',
                    this.password_confirmation,
                  )
                }

                if (this.selectRoleId && Array.isArray(this.selectRoleId)) {
                  this.selectRoleId.forEach(roleId => {
                    formData.append('role_id[]', roleId) // Append each role ID individually
                  })
                }

                if (this.selectDesignationId) {
                  formData.append('designation_id', this.selectDesignationId)
                }
                if (this.selectDepartmentId) {
                  formData.append('department_id', this.selectDepartmentId)
                }

                if (this.selectDesignationId) {
                  formData.append('designation_id', this.selectDesignationId)
                }

                if (this.selectAttendancePolicyId) {
                  formData.append('remote_attendance_policy_map_id', this.selectAttendancePolicyId)
                }
                if (this.selectGenderId) {
                  formData.append('gender', this.selectGenderId)
                }

                if (this.selectEmployeeTypesId) {
                  formData.append(
                    'employee_type_id',
                    this.selectEmployeeTypesId,
                  )
                }

                if (this.festivalBonusEligibilityMonths) {
                  formData.append(
                    'festival_bonus_eligibility_month',
                    this.festivalBonusEligibilityMonths,
                  )
                }

                formData.append('machine_user_id', this.machineEmployeeId)

                if (this.selectLeaveTypesIds.length > 0) {
                  this.selectLeaveTypesIds.forEach(element => {
                    formData.append('leave_type_id[]', element)
                  })
                }

                if (this.employeeNumber) {
                  formData.append('employee_number', this.employeeNumber)
                }

                if (this.agreementRenewalDate) {
                  formData.append(
                    'agreement_renewal_date',
                    this.agreementRenewalDate,
                  )
                }

                await this.$api.post('/api/users', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })

                this.isUserFormSubmitLoading = false
                this.hiddenModal()

                this.loadItems()

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully Created',
                  },
                })
              }
            } catch (error) {
              this.isUserFormSubmitLoading = false
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error?.response?.data?.message,
                  },
                })
              }

              if (error?.response?.data?.errors) {
                this.$refs.usersValidation.setErrors(
                  error?.response?.data?.errors,
                )
              }
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
.custom-font {
  font-size: 13px !important;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.flatpickr-wrapper{
  width: 100% !important;
}

.flatpickr-input[readonly]:disabled {
  color: #d3d3d3 !important; /* Custom color (light gray in this case) */
  background-color: transparent !important;
  cursor: not-allowed;
}

</style>
